import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { employesUnderline } from 'images/koppelingen'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { treams, treamsScreenshot } from 'images/koppelingen'
import Video from "components/video"

const dummyKlantVerhaal = {
  pageTitle: '“Makkelijk contact en duidelijkheid voor iedereen”',
  pageStreamer: 'Marieke de Vos, Codaisseur',
  useCase: 'Founders',
  person: {
    name: 'Marieke',
    functionTitle: 'HR MANAGER, ebay',
    companyLogo: 'https://via.placeholder.com/129x23',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: 'https://via.placeholder.com/344x224/ff48df',
  },
  content: [{
    title: 'Over Treams',
    description: '80% van de medewerkers vertrekken omdat ze zich niet gehoord voelen, laten we dat veranderen! Met het alles in één groeiplatform kun je doelen stellen, 360 feedback ontvangen, inzicht krijgen in medewerker engagement en gesprekken voeren. Dit alles met de medewerker in de hoofdrol. Samen motiveren we de medewerker om zelf controle te nemen over zijn of haar persoonlijke groei en ontwikkeling. Creëer een continue gesprekscyclus, breng talent in kaart en neem actie op negatief sentiment met inzichten uit de engagement pulse surveys, voordat het te laat is. Treams wordt jouw samenwerkingspartner. Volg de Treams opleiding en leer alle best practices voor het motiveren voor groei en activatie van het platform. Probeer het platform 14 dagen gratis of plan een demo via de website.'
    }, {
      title: 'De werking van de koppeling met Treams',
      description: 'De Employes integratie van Treams zorgt dat de medewerkersgegevens naadloos worden overgenomen. Je voert de medewerkergegevens in in jouw Employes omgeving en deze worden eenmaal per dag automatisch gesynchroniseerd. Jouw medewerkers kunnen dan gebruik maken van alle modules van het Treams groeiplatform. Zo gaan ze aan de slag met het vragen van feedback, het stellen van doelen en het voeren van gesprekken. Zorg dat medewerkers zich gehoord voelen en verhoog je medewerkers retentie. Daarnaast  stimuleer je persoonlijke groei en ontwikkeling.',
    },{
      title: 'Functies van de koppeling',
      list: ['Synchroniseer automatisch de medewerkergegevens en functiegegevens',
      'Koppel automatisch de juiste templates aan de medewerkers op basis van functie',
      'Installeer de koppeling met één druk op de knop en ga van start binnen 5 minuten',
    'Bespaar tijd en energie doordat nieuwe medewerkers meteen worden aangemeld',
  'De koppeling is ISO 27001 gecertificeerd en GDPR compliant']
    }, {
      image: treamsScreenshot
    }, {
      title: 'Tarieven van de koppeling',
      description: 'Treams rekent een bedrag van € 125,- per maand voor de koppeling (exclusief licentiekosten Treams). Vanuit Employes zijn er geen extra kosten.'
  }]
}

class Treams extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling Treams & Employes | Performance management</title>
            <meta name="description" content="Een uitbreiding van je salarisadministratie met performance management. Ontdekt de koppeling van Treams."/>
            <meta itemprop="name" content="Koppeling Treams & Employes | Performance management" />
            <meta itemprop="description" content="Een uitbreiding van je salarisadministratie met performance management. Ontdekt de koppeling van Treams."/>
            <meta itemprop="image" content="/static/meta-img.png" />
          </Helmet>

          <header className="koppeling treams padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Koppel Treams aan <span className="hide-mobile">je salarisadministratie in</span> Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employesUnderline} alt="employes logo"/>

                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#DAE6F0" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={treams} alt="Treams logo"/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyKlantVerhaal.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const isVideo = (firstObjectKey === 'video')
                  const { title, description, list } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isVideo ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isVideo ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                                <h4>{ title }</h4>
                                {description && (<p>{description}</p>)}
                                {list && list.length && (<ul>{list.map(point => (<li>{point}</li>))}</ul>)}
                              </div>
                            ),
                            image: (
                              <img src={section.image} alt="section" />
                            ),
                            video: (
                                <Video videoSrcURL={section.video} videoTitle="Koppeling Moneybird en Employes salarisadministratie"/>
                            )
                          }[firstObjectKey]}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <a href="https://treams.com/nl/" target="_blank" rel="nofollow">Lees meer over Treams</a>
                </div>
              </div>
            </div>
          </div>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

Treams.propTypes = {
  data: PropTypes.object
}

export default Treams
